.sunset {
  max-width: 100%;
  max-height: 100vh;
  margin: auto;
}

.app {
  color: white;
  background-color: #e7e7ff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}
